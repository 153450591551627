<template>
  <div
      id="app"
      v-loading.fullscreen.lock="store.loadingRef > 0"
      element-loading-text="Loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <keep-alive>
      <router-view class="main"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'App',
  data() {
    return {
      store,
    };
  },
};
</script>

<style lang="scss">
html,
body {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: auto;
}

#app {
  height: 100%;
}

#app .main {
  height: 100%;
}

.viewer-container {
  z-index: 99999 !important;
}

.el-table .cell {
  padding-right: 8px !important;
}

.el-table .cell, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
  padding-left: 8px !important;
}
</style>
