import Vue from 'vue';
import VueI18n from 'vue-i18n';
import bnLocate from 'element-ui/lib/locale/lang/bn';
import zhLocate from 'element-ui/lib/locale/lang/zh-CN';
import locale from 'element-ui/lib/locale';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: process.env.DEPLOY_CONFIGS.lang || 'bn',
  messages: {
    bn: {...require('./lang/bn.json'), ...bnLocate},
    zh: {...require('./lang/zh.json'), ...zhLocate},
  },
});

locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
