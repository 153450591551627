import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import i18n from '@/i18n';
import VueClipboard from 'vue-clipboard2';
import 'vant/lib/index.less';
import {Toast, Popup, Picker, Button, Dialog, Field } from 'vant';
import {Loading, Select, Option} from "element-ui";
import JSEncrypt from "jsencrypt"; //引入模块
import './style.less';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.prototype.$jsEncrypt = JSEncrypt; //配置全局变量
let $jse = new JSEncrypt();
$jse.setPublicKey(process.env.DEPLOY_CONFIGS.key);
Vue.prototype.$jse = $jse;
Vue.use(VueClipboard).use(Toast).use(Popup).use(Loading.directive)
    .use(Picker).use(Button).use(Select).use(Dialog).use(Field)
    .use(Option).use(Viewer, {
    defaultOptions: {
        zIndex: 1, // 层级
        inline: false, // 启用 inline 模式
        button: true, // 显示右上角关闭按钮
        navbar: false, // 显示缩略图导航
        title: false, // 显示当前图片标题
        toolbar: false, // 工具栏
        tooltip: true, // 显示缩放百分比
        movable: true, // 图片是否可以移动
        zoomable: true, // 图片是否可以缩放
        rotatable: false, // 图片是否可旋转
        scalable: false, // 图片是否可翻转
        transition: true, // css3过度
        fullscreen: true, // 播放时是否全屏
        keyboard: false, // 是否支持键盘
        loop: false, // 图片是否循环播放
    }
});
new Vue({
    router,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
