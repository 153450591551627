export default {
  loadingRef: 0,
  addLoadingRef() {
    this.loadingRef += 1;
  },
  minusLoadingRef() {
    if (this.loadingRef > 0) {
      this.loadingRef -= 1;
    }
  },
};
