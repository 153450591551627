import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      name: 'index',
      path: '/:orderId',
      component: (resolve) => {
        require(['./views/Index'], resolve)
      }
    },
    {
      name: 'detail',
      path: '/detail/:orderId',
      component: (resolve) => {
        require(['./views/Detail'], resolve)
      }
    },
  ],
});

export default router;
